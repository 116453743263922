<template>
  <div class="register">
    <div class="register-wrap content_area">
      <h2>
        {{ $t("companyInfo") }}
      </h2>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        :label-width="LOCALE === 'en' ? '145px' : '100px'"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="$t('dylancompanyName')"
          prop="name"
          :rules="[{ required: true, message: ' ', trigger: 'change' }]"
        >
          <el-input
            @focus="openCompany"
            size="small"
            v-model="ruleForm.name"
            :placeholder="$t('pleaseinputcompany')"
            :disabled="companyExists"
          ></el-input>
        </el-form-item>
        <el-form-item
            v-if="isShowInputInfo"
          :label="$t('companyIndustry')"
          prop="industry"
          :rules="[{ required: true, message: ' ', trigger: 'change' }]"
        >
          <el-cascader
            style="width: 100%"
            v-model="ruleForm.industry"
            :options="Industry"
            @change="changeIndustry"
            :placeholder="$t('pleaseSelect')"
            size="small"
            :show-all-levels="false"
          ></el-cascader>
        </el-form-item>
        <el-form-item class="clearfix" :label="$t('BussinessClassification')" v-if="isShowInputInfo">
          <div class="fl tagDiv" style="width: 70%">
            <div
              :title="tag | bussinessFormat(LOCALE)"
              v-for="(tag, index) in selectAssocia"
              :key="index"
              class="fl textOverflow"
            >
              {{ tag | bussinessFormat(LOCALE) }}
            </div>
          </div>
          <el-button
            class="fr"
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="add"
            >{{ $t("add") }}</el-button
          >
        </el-form-item>
        <el-form-item
            v-if="isShowInputInfo"
          :label="$t('Entrytype')"
          prop="entrType"
          :rules="[{ required: true, message: ' ', trigger: 'change' }]"
        >
          <el-select
            size="small"
            style="width: 100%"
            v-model="ruleForm.entrType"
            popper-class="registerIndustry"
            :placeholder="$t('pleaseSelect')"
          >
            <el-option
              v-for="(item, index) in entrTypeList"
              :key="index"
              :value="item.exhibition_type_zh"
              :label="
                item.exhibition_type_en
                  | priorFormat(item.exhibition_type_zh, LOCALE)
              "
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button
          :loading="loading"
          type="primary"
          size="small"
          class="enrollBut"
          @click="submitForm('ruleForm')"
          >{{ $t("next") }}
        </el-button>
      </el-form>
    </div>
    <register ref="registerCompany" @confirmJoin="confirmJoin"></register>
    <el-dialog
      :title="$t('BussinessClassification')"
      :visible.sync="dialogVisible"
      width="900px"
      :close-on-click-modal="false"
    >
      <BussinessClassification
        :isSelect="isSelect"
        :number="5"
        :isCenter="true"
        :type="'1'"
        :classification="classification"
        v-if="dialogVisible"
        @closeClass="closeClass"
        @saveClass="saveClass"
      ></BussinessClassification>
    </el-dialog>
  </div>
</template>

<script>
import { Industry } from "@/baseUtils/enumValue";
import register from "@/baseComponents/registerCompany";
import BussinessClassification from "@/baseComponents/BussinessClassification";
import { getBusinessObject, priorFormat } from "@/basePlugins/filters";
import { businessList } from "@/baseUtils/enumValue";
export default {
  name: "two",
  components: {
    register,
    BussinessClassification,
  },
  data() {
    return {
      businessList,
      ruleForm: {
        name: null,
        industry: null,
        entrType: null,
        participation: null,
        booth: null,
      },
      rules: {},
      loading: false,
      selectAssocia: [],
      isSelect: {},
      Industry: [],
      //所选公司
      oldCompanyInfo: {},
      newCompanyInfo: {},
      companyInfo: {},
      dialogVisible: false,
      classification: [],
      entrTypeList: [],
      hasDataList: [
        {
          attend_way: 3,
          exhibition_type_en: "Paying visitor",
          exhibition_type_zh: "付费观众",
        },
        {
          attend_way: 4,
          exhibition_type_en: "Free visitor",
          exhibition_type_zh: "免费观众",
        },
      ],
      participationList: [],
      user_id: null,
      meeting_id: null,
      company_id: null,
      companyExists: false,
      //是否展示企业信息输入框
      isShowInputInfo:true,
      companyEnrollInfo:null,//企业报名信息
    };
  },
  watch: {
    selectAssocia() {
      this.handleBussiness();
    },
  },
  created() {
    this.user_id = this.$store.state.baseStore.userInfo.id;
    this.meeting_id = this.MEETING_ID;
    //是否有企业
    if (this.$store.state.baseStore.userInfo.company_id) {
      //有企业判断是否报过名
      if(this.ENROLL_INFO.is_company_enroll){
        //已报名
        this.goToTwo()
      }else{
        //未报名
        this.oldCompanyInfo.company_id = this.$store.state.baseStore.userInfo.company_id;
        this.company_id = this.$store.state.baseStore.userInfo.company_id;
        let name = priorFormat(
            this.$store.state.baseStore.userInfo.company_name_en,
            this.$store.state.baseStore.userInfo.company_name_zh,
            this.LOCALE
        );
        this.ruleForm.name = name;
        this.getCompanyBussness();
        this.companyExists = true;
      }

    }else{
      //没有企业
      this.isShowInputInfo=false
    }
  },
  mounted() {
    if (this.LOCALE == "en") {
      Industry.forEach((element) => {
        element.label = element.label_en;
        element.children.forEach((ele) => {
          ele.label = ele.label_en;
        });
      });
    } else {
      Industry.forEach((element) => {
        element.label = element.label_zh;
        element.value = element.label_zh;
        element.children.forEach((ele) => {
          ele.label = ele.label_zh;
        });
      });
    }
    this.Industry = Industry;
    this.getJoinMeetingType();
  },
  methods: {
    async getCompanyBussness() {
      let params = {};
      params.company_id = this.$store.state.baseStore.userInfo.company_id;
      let res = await this.$store.dispatch(
        "register/obtainCompanyBussniess",
        params
      );
      if (res.success) {
        let data = res.data;
        if (data.company_industry) {
          this.Industry.forEach((element, index) => {
            element.children.forEach((ele) => {
              if (ele.value == data.company_industry) {
                this.ruleForm.industry = [
                  this.Industry[index].label,
                  ele.value,
                ];
              }
            });
          });
        }
        let business = this.handleData(data.business_classification);
        this.selectAssocia = business.map((item) => item.id);
      }
    },
    changeIndustry() {},
    //处理优势业务数据
    handleBussiness() {
      let isSelect = {
        Convertional: [],
        "Dangerous Cargo": [],
        "Other convertional": [],
        "Particular Cargo": [],
        Services: [],
      };
      let arr = [];
      this.businessList.forEach((item, index) => {
        this.selectAssocia.forEach((ee, vv) => {
          if (ee === item.id) {
            arr.push(item);
          }
        });
      });

      arr.forEach((item, index) => {
        if (item.name_en === "Convertional") {
          isSelect["Convertional"].push(item);
        }
        if (item.name_en === "Dangerous Cargo") {
          isSelect["Dangerous Cargo"].push(item);
        }
        if (item.name_en === "Other convertional") {
          isSelect["Other convertional"].push(item);
        }
        if (item.name_en === "Particular Cargo") {
          isSelect["Particular Cargo"].push(item);
        }
        if (item.name_en === "Services") {
          isSelect["Services"].push(item);
        }
      });
      this.isSelect = isSelect;
    },
    add() {
      this.classification = getBusinessObject([], true);
      this.dialogVisible = true;
    },
    async confirmJoin(val) {
      if (val.result.company_id) {
        this.ruleForm.name = val.name;
        this.company_id = val.result.company_id;
        //查看公司是否报名了
        let query={}
        query.company_id=val.result.company_id
        query.meeting_id=this.meeting_id
        let enrollRes=await this.$store.dispatch("register/companyRegisterInfo",query)
        if(enrollRes.success){
          this.companyEnrollInfo=enrollRes.data
          //判断是否报过名
          if(enrollRes.data.is_company_enroll){
            //报过名
            this.oldCompanyInfo = val.result;
          }else{
            //未报名
            this.isShowInputInfo=true
            let params = {};
            params.company_id = val.result.company_id;
            let res = await this.$store.dispatch(
                "register/getCompanyBussess",
                params
            );
            if (res.success) {
              val.industry = res.data.company_industry;
              val.business_info = {};
              val.business_info.business_classification =
                  res.data.business_classification;
            }
            this.oldCompanyInfo = val.result;
            if (val.industry) {
              this.Industry.forEach((element, index) => {
                element.children.forEach((ele) => {
                  if (ele.value == val.industry) {
                    this.ruleForm.industry = [
                      this.Industry[index].label,
                      ele.value,
                    ];
                  }
                });
              });
            }
            let business = this.handleData(
                val.business_info.business_classification
            );
            this.selectAssocia = business.map((item) => item.id);
          }
        }
      } else {
        //新企业
        this.isShowInputInfo=true
        this.newCompanyInfo = val.result;
        this.ruleForm.name = val.name;
      }
      this.selectCompanyInfo = val;
      this.$refs.registerCompany.closePanel();
    },
    handleData(obj) {
      let arr = [];
      for (let i in obj) {
        arr = arr.concat(obj[i]);
      }
      return arr;
    },
    async getJoinMeetingType() {
      let params = {};
      params.meeting_id = this.meeting_id;
      try {
        let res = await this.$store.dispatch(
          "register/joinMeetingTyoe",
          params
        );
        if (res.success) {
          res.data.forEach((item, index) => {
            if (item.attend_way === 1) {
              this.entrTypeList.push(item);
            } else if (item.attend_way === 2) {
              this.entrTypeList.push(item);
            }
          });
          this.entrTypeList = this.entrTypeList.concat(this.hasDataList);
        }
      } catch (e) {}
    },
    openCompany() {
      this.$refs.registerCompany.openPanel();
    },
    closeClass() {
      this.dialogVisible = false;
    },
    async saveClass(e) {
      this.selectAssocia = e;
      this.dialogVisible = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormFn();
        } else {
          return false;
        }
      });
    },
    async submitFormFn() {
      if (this.selectAssocia.length > 5) {
        this.$message.warning(this.$t("maxNumberFive"));
        return;
      }
      if (this.$store.state.baseStore.userInfo.company_id) {
        try {
          this.loading = true;
          let params = {};
          params.company_id = this.$store.state.baseStore.userInfo.company_id;
          params.industry = this.ruleForm.industry[1];
          params.business_classification = this.selectAssocia.join(",");
          params.kind = 1;
          params.user_id = this.USER_INFO.id;
          let res = await this.$store.dispatch(
            "register/companyBindBussess",
            params
          );
          if (res.success) {
            let query = {};
            query.meeting_id = this.meeting_id;
            query.company_id = this.$store.state.baseStore.userInfo.company_id;
            let data = this.entrTypeList.find(
              (item) => item.exhibition_type_zh === this.ruleForm.entrType
            );
            query.apply_type = data.attend_way;
            if (data.attend_way === 2) {
              if (data.exhibition_type_zh === "标准展位") {
                query.booth_type = 1;
              } else if (data.exhibition_type_zh === "特装展位") {
                query.booth_type = 2;
              } else if (data.exhibition_type_zh === "迷你展位") {
                query.booth_type = 3;
              }
            }
            let result = await this.$store.dispatch(
              "register/companyJoinMeeting",
              query
            );
            if (result.success) {
              this.loading = false;
              this.goToTwo();
            } else {
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        } catch (e) {
          this.loading = false;
        }
      } else {
        //用户没有公司
        try {
          this.loading = true;
          let params = {};
          if (this.oldCompanyInfo.company_id) {
            params.company_id = this.company_id;
            if (this.oldCompanyInfo.country) {
              params.country = this.oldCompanyInfo.country;
            }
            if (this.oldCompanyInfo.region_en) {
              params.region_en = this.oldCompanyInfo.region_en;
            }
            if (this.oldCompanyInfo.region_zh) {
              params.region_zh = this.oldCompanyInfo.region_zh;
            }
          } else {
            if (this.LOCALE === "en") {
              params.company_name_en = this.ruleForm.name;
              params.region_en = this.newCompanyInfo.region_en;
            } else {
              params.company_name_zh = this.ruleForm.name;
              params.region_zh = this.newCompanyInfo.region_zh;
            }
            params.country = this.newCompanyInfo.country;
          }
          params.user_id = this.user_id;
          if(this.ruleForm.industry){
            params.industry = this.ruleForm.industry[1];
          }
          if(this.selectAssocia.length){
            params.business_classification = this.selectAssocia.join(",");
          }
          let res = await this.$store.dispatch(
            "register/companyRegister",
            params
          );
          if (res.success) {
            //如果已经报名了就跳到第二个页面
            if(this.companyEnrollInfo&&this.companyEnrollInfo.is_company_enroll){
              this.goToTwo();
              return
            }
            this.company_id = res.data;
            let query = {};
            query.meeting_id = this.meeting_id;
            query.company_id = this.company_id;
            if (this.oldCompanyInfo.country) {
              query.country = this.oldCompanyInfo.country;
            }
            if (this.oldCompanyInfo.region_en) {
              query.region_en = this.oldCompanyInfo.region_en;
            }
            if (this.oldCompanyInfo.region_zh) {
              query.region_zh = this.oldCompanyInfo.region_zh;
            }
            let data = this.entrTypeList.find(
              (item) => item.exhibition_type_zh === this.ruleForm.entrType
            );
            query.apply_type = data.attend_way;
            if (data.attend_way === 2) {
              if (data.exhibition_type_zh === "标准展位") {
                query.booth_type = 1;
              } else if (data.exhibition_type_zh === "特装展位") {
                query.booth_type = 2;
              } else if (data.exhibition_type_zh === "迷你展位") {
                query.booth_type = 3;
              }
            }
            let result = await this.$store.dispatch(
              "register/companyJoinMeeting",
              query
            );
            if (result.success) {
              this.loading = false;
              this.goToTwo();
            } else {
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        } catch (e) {
          this.loading = false;
        }
      }
    },
    goToTwo() {
      this.$router.push({
        path: "registerTwo",
      });
    },
  },
};
</script>

<style scoped lang="less">
.register {
  padding: 24px 0;
  background: #f6f7f9;
  .register-wrap {
    box-sizing: border-box;
    min-height:391px;
    padding: 40px 420px;
    background: #fff;
    h2 {
      font-weight: 600;
      font-size: 16px;
      margin: 0 0 10px 30px;
    }
  }
  .enrollBut {
    width: 300px;
    margin: 10px auto 0;
    display: block;
    position: relative;
    left: 11px;
  }
}
.tagDiv {
  border: 1px solid #e4e7ed;
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  display: flex;
  display: -moz-box; /* Firefox */
  div {
    margin-right: 5px;
    min-width: 15%;
  }
}
</style>
